import * as React from "react"
import "../styles/about-us.scss";
import "../styles/responsive.scss";
import fibserSvg from '../images/svg/fibser-svg.svg';
import fibserWhiteSvg from '../images/svg/fibser-white-svg.svg';
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
    return (
        <>
            <Helmet
                title={"Fibsr"}
            />
            <main className="about-us-page">
                <header className="about-us-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-wrapper">
                                    <a href="/">
                                      <img src={fibserSvg} />
                                    </a>
                                    <div className="right-section">
                                        <a href="#">About Us</a>
                                        {/* <a href="#">Contact</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="aboutus-page-body">
                    <section className="about-us-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <div className="main-heading">About <span>Fibsr.</span></div>
                                        <div className="sub-heading">Our Manifesto</div>
                                        <div className="main-quote">“ We want people Build their own App easily with No Code Experience”</div>
                                        <div className="main-info">
                                            <span>Fibsr.</span> is a tech Platform that help individuals to build their own App. It is a platform that allows users to create Apps or multiple Apps with the help of easy and ready made templates. Users can easily create their App and publish it on App Store and Playstore. Want to launch Ed-Tech, have an idea for startup or want to start your own Business via App.
                                            Create it, launch it and let your business grow today. No more expensive tech expenses, everything at your fingertip.
                                            We want everyone to dream big and we will help them build it.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="footer-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <a href="/">
                                          <img src={fibserWhiteSvg} className="img-fluid footer-logo" />
                                        </a>
                                        <div className="right-section">
                                            <a href="#">About Us</a>
                                            {/* <a href="#">Contact</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>

    )
}

export default AboutUsPage